<template>
  <div class="main">
    <headlogin></headlogin>
    <div class="content">
      <div class="cons">
        <div class="login-title">
          <div class="login-title-wz">个人中心</div>
        </div>
        <div class="grzx-con">
          <div class="custom-block">基础信息</div>
          <div class="grzx-con-list">
            <div class="grzx-con-list-left">
              <div class="grzx-con-list-title">用户名：</div>
              <div class="grzx-con-list-nr">13012345678</div>
            </div>
            <div class="grzx-con-list-right">
              <el-button type="primary" @click="updatepsw">修改密码</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 修改密码 -->
    <el-dialog v-model="dialogFormVisible" title="修改密码" width="600px">
      <el-form :model="form">
        <el-form-item label="原密码" :label-width="formLabelWidth">
          <el-input v-model="form.oldpassword" type="password" show-password placeholder="请输入登录密码"/>
        </el-form-item>
        <el-form-item label="新密码" :label-width="formLabelWidth">
          <el-input v-model="form.newpassword" type="password" show-password placeholder="请设置新密码"/>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="clickxgpsw">
            确认修改
          </el-button>
        </span>
      </template>
    </el-dialog>
    <footers></footers>
  </div>
</template>

<script setup>
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
// 是否显示修改密码
let dialogFormVisible=ref(false)
const formLabelWidth = '140px'
let form=reactive({
  oldpassword:'',
  newpassword:'',
})
// 修改密码
const updatepsw = () => {
  dialogFormVisible.value=true
};
// 提交修改
const clickxgpsw=()=>{
  dialogFormVisible.value=false
}
</script>

<style scoped>
.main {
  background: #fff;
}
.content {
  width: 1200px;
  height: auto;
  margin: auto;
}
.cons {
  width: 600px;
  margin: auto;
  height: 500px;
  padding: 30px 0;
}
.login-title {
  padding: 20px 0;
}
.login-title-wz {
  font-size: 24px;
  text-align: center;
}
.custom-block {
  padding: 8px 16px;
  background-color: #ecf5ff;
  border-radius: 4px;
  border-left: 3px solid #409eff;
  margin: 15px 0 15px 0;
}
.grzx-con-list::after {
  content: "";
  display: table;
  clear: both;
}
.grzx-con-list-left {
  float: left;
  width: 400px;
  height: 40px;
  display: flex;
  align-items: center;
}
.grzx-con-list-right {
  float: right;
  width: 200px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.grzx-con-list-title {
  font-weight: bold;
}
</style>
